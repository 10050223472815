import {useNavigate} from "react-router-dom";
import React from "react";
import {Col, Content, Link, Paragraph, Row, Space, Title} from "../layout/Content";
import {InlineImage} from "../layout/Defaults";
import {Menu} from "../Generated";
import {Trademark} from "../part/Trademark";

export const Navigation = () => {
    const navigate = useNavigate();

    return (<Space justify={"space-between"} style={{width: "100%"}}>

        <Space Wrap>
            <Link className={window.location.pathname === "/" ? "active" : ""} href={"/"} onClick={() => {
                navigate("/");
            }}>Home</Link>
            <Link className={window.location.pathname === "/articles" ? "active" : ""} href={"/articles"}
                  onClick={() => {
                      navigate("/articles");
                  }}>Articles</Link>
            <Link href={"#"}>Work</Link>
            <Link href={"#"}>APIs & Documentation</Link>
        </Space>
        <Space>
            <Link href={"#"}>Sign In</Link>
        </Space>
    </Space>);
}


export const Footer = () => {


    return (<footer className="app-footer">
        <Content>
            <Row>
                <Col xs={24}>
                    <div className="footer wide limits">
                        <Paragraph>Copyright 2023&mdash;{new Date().getFullYear()} <strong><em>ClusterFX</em></strong> by <strong>OakFrame Interactive Ltd.</strong></Paragraph>
                        <Space GapSm>
                            <Link href={"/info/privacy-policy"} className={"Small"}>Privacy Policy</Link> <Paragraph Muted>|</Paragraph>
                            <Link href={"/info/terms-of-service"} className={"Small"}>Terms of Service</Link> <Paragraph Muted>|</Paragraph>
                            <Link href={"/info/cookie-policy"} className={"Small"}>Cookie Policy</Link>
                        </Space>
                    </div>
                </Col>
            </Row>
        </Content>
    </footer>);
}


export const Header:React.FC<{subtitle?:React.ReactNode}> = ({subtitle}) => {


    return (<header className="app-header">
        <Row>
            <Col xs={24}>
                <div className={"wide limits"}>

                        <Space justify={"space-between"}><Link span href={"/"}><Title><InlineImage src={"/asset/shape-transparent.png"}/> <strong>ClusterFX</strong><Trademark/> <span
                            className={"hide-sm muted-heavy"}>{subtitle}</span></Title></Link>

                            <Menu button/>
                        </Space>

                </div>
            </Col>
        </Row>
    </header>);
}

